import React from 'react';

import Layout from '../components/Layout';
import Footer from '../components/Footer';
import SocialLinks from '../components/SocialLinks';
import Header from '../components/Header';
import Link from 'gatsby-link';
import GuatemalaProject from '../components/guatemalaproject';


/**
 * This is the home page for the website. Index.js is the default file for gatsby.js to start the program.
 */

const IndexPage = () => (

  <Layout>
    {/* Header class that defines the header of the website*/}
    <Header />
  <div className="container-fluid p-0">
    
    <header className="masthead" >
      <div className="responsive-box-for-index  d-flex align-items-center">
        
        <div className=" text-left pl-3 align-items-center">
          < h2 className="has-text-weight-bold is-size-1 pl-4" style={{ text: 'Noticia Text', color: 'white', }}>{"Change the world one home "} </h2>
          < h2 className="has-text-weight-bold is-size-1 pl-4" style={{ text: 'Noticia Text', color: 'white',  }}>{"and two families at a time"} </h2>
          
          <div className ="responsive-button-for-index">
          <Link to="/donate" className="btn-lg btn-primary rounded-pill px-5 pt-3" title="Donate">
            Do More
            </Link>
            </div>
        </div>
      </div>
    </header>
    </div>
    
    <GuatemalaProject />
    <SocialLinks />
    <Footer />

  </Layout>
);

export default IndexPage;
