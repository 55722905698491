import React from 'react';
import Layout from './Layout';
import Header from './Header';
import process from '../assets/images/Process.png';
import girls from '../assets/images/girls.jpg';
import kidsRight from '../assets/images/kids-right.png';
import kidsLeft from '../assets/images/kids-left.jpg';
import donations from '../assets/images/donations.jpg';
import blueFamily from '../assets/images/blue-family.jpg';
import ReactPlayer from 'react-player'
import finishedHouse from '../assets/images/finished-house.jpg';
import childrenClassroom from '../assets/images/children-classroom.jpg';
export default function GuatemalaProject() {
  return (
    <Layout>
      <section id="purpose" className="smoked-background p-0 m-0 ">
        <div className="container-fluid p-0">
          <div className="p-5 text-center">
            <h4 className='p-1'>We build homes for deserving single mothers in Guatemala to break family poverty cycles.</h4>
          </div>

          <PhaseLayoutDark source={blueFamily} header={"Phase 1: Identify Deserving Families"} text={"To find suitable families to sponsor in Guatemala, Two Beta works with local community and religious leaders in the towns of Patalul and Nahuala. As time has gone on, it has become evident that many of these families were making every effort to help themselves, but naturally indigent circumstances made it impossible to focus on providing for anything greater than the bare necessities of life. These circumstances pressured the children to drop out of school to work, which in turn led to systemic perpetuation of prior impoverished generation's experience."} />
          <PhaseLayoutLight source={donations} header={"Phase 2: Raise Funds"} text={"Two Beta, is able to finance the production of a home in these rural villages for only $10,000 to $14,000. In order to finance these homes, Two Beta has partnered with Arrived Investment Company who has committed to donating $6000 to Two Beta for every home they build as part of their ”Home for a Home“ campaign. These donations finance approximately half of the construction of our homes. The remainder of the financing comes from individual and organizational one time and recurring donations."} />
          <PhaseLayoutDark source={finishedHouse} header={"Phase 3: Invest in Local Contractors & Developers"} text={"Mi Casa is a Mexican based company that has agreed to franchise their blueprints and manufacturing capabilities to Guatemalan workforce. Arrived will be a key customer and catalyst for growth for entrepreneurs using the Mi Casa model in Guatemala."} />
          <PhaseLayoutLight source={kidsLeft} header={"Phase 4: Build High-Quality Shelter"} text={"Mi Casa has blueprints for single bedroom, two bedroom and three bedroom homes. Mi Casa homes are basic structures that are improvements over the current living conditions of the sponsored families, but are also appropriate for the lifestyle and neighborhoods of the families for whom they are built."} />
          <PhaseLayoutDark source={childrenClassroom} header={"Phase 5: Educate Local Children"} text={"Many of the families Two Beta works with have children that have had to drop out of school due to a lack of resources. Many of the mothers were never able to finish school, either, which further impacts educational prospects of the children. In partnership with Arrived Investment Company and other organizations, Two Beta offers scholarships and cash incentives to help children and mothers continue to pursue education."} />

        </div>
      </section>
    </Layout >
  )
}
function ResponsivePlayer() {
  return (
    <div style={{ position: 'relative', paddingTop: '52.8%' }}>
      <ReactPlayer
        style={{ position: 'absolute', top: '0', left: '0' }}
        url="https://www.youtube.com/watch?v=lBLDdwgd8PM"
        light={false}
        width='100%'
        height='100%'
      />
    </div>
  )
}

function PhaseLayoutDark({ source, header, text }) {
  return (
    <div class="container-fluid p-0 ">
      <div class="row no-gutters secondary-background">
        <div class="col-md-5">
          <img src={source} class="w-100" alt="..." />
        </div>
        <div class="col-md-7 align-self-center  ">
          <div class="card-body">
            <h4 class="card-title">{header}</h4>
            <p class=" ">{text}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

function PhaseLayoutLight({ source, header, text }) {
  return (
    <div class="container-fluid p-0 ">
      <div class="row no-gutters">
        <div class="col-md-7 align-self-center">
          <div class="card-body">
            <h4 class="card-title">{header}</h4>
            <p class=" ">{text}</p>
          </div>
        </div>
        <div class="col-md-5">
          <img src={source} class="w-100" alt="..." />
        </div>
      </div>
    </div>
  )
}

